<template>
    <div class="edu-frame">
        <edu-nav-bar title="退款查询" :show="show" @showPopup="show=true"
                     @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <div class="edu-group" style="margin: 20px auto 15px;">
                <div class="title-tips">你的退款班级列表：</div>
            </div>
            <div v-for="(term, key) in regClassMasters" :key="key">
                <div v-for="(rms, index) in term" :key="index">
                    <div v-for="(master, mkey) in rms" :key="mkey">
                        <div class="class-list">
                            <div class="edu-group class-item" style="flex-direction: column;">
                                <div class="class-detail-title" style="color: red;">{{key}}</div>
                            </div>
                            <div class="edu-group class-item" style="flex-direction: column;">
                                <div class="class-detail">
                                    <div class="class-detail-title">退款单号：{{mkey}}</div>
                                    <div class="class-detail-more">退款时间：{{master[0].refundDatetime | filterDate}}</div>
                                </div>
                            </div>
                            <div v-for="(detail, detailIndex) in master" :key="detailIndex">
                                <div class="edu-group class-item">
                                    <div class="class-detail">
                                        <div class="class-detail-title">
                                            <div class="class-detail-title-name">{{detail.className}}</div>
                                            <van-tag type="success" v-if="detail.isRefund == 1">已退费</van-tag>
                                            <van-tag type="warning" v-if="detail.isRefund == 2">退费中</van-tag>
                                        </div>
                                        <div class="class-detail-more">
                                            <div class="class-detail-title-name">
                                                <div>上课时间：{{detail.classTime}}</div>
                                                <div class="class-detail-row">
                                                    <div class="class-detail-row-title">报读时间：{{detail.regDate | filterDate}}</div>
                                                </div>
                                                <div class="class-detail-row">
                                                    <div class="class-detail-row-title">学费：<label class="txt-price">{{detail.rcMoney}}元</label></div>
                                                    <div class="class-detail-row-title">退款金额：<label class="txt-price">{{detail.refundMoney}}元</label></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="class-split"/>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import {Tag} from "vant";
    import EduNavBar from "@/components/EduNavBar";
    import BaomingApi from "@/api/BaomingApi";
    import Tools from "@/api/Tools";
    import EduConstants from '@/api/EduConstants';
    import XEUtils from "xe-utils";

    export default {
        components: {
            EduNavBar,
            VanTag: Tag
        },
        filters: {
            filterDate(date) {
                return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
            }
        },
        data() {
            return {
                cust: Tools.getCurCust(),
                LOGINTYPE_CHILD: EduConstants.LOGINTYPE_CHILD,
                show: false,
                regClassMasters: [],
                datas: [],

                loading: false
            }
        },
        methods: {
            getRefundClasses() {
                BaomingApi.getRefundClasses({custId: this.cust.custId, loginType: this.cust.loginType}).then(response => {
                    console.log(response.res)
                    this.regClassMasters = response.res
                })
            }
        },
        mounted() {
            this.getRefundClasses()
        }
    }
</script>
<style scoped>
    .content-frame {
        height: calc(100% - 46px);
    }
    .class-list {
        width: 95%;
        border-radius: 8px;
        border: 1px solid lightgrey;
        box-shadow: 1px 2px 2px #ddd;
        margin: 10px auto;
        padding: 8px 0px;
        background-color: #cde7f7;
    }

    .title-tips {
        margin-left: 2%;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        color: black;
    }

    .class-split {
        width: 95%;
        height: 1px;
        border-top: 2px dotted lightgrey;
    }
</style>
